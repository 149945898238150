<template>
  <div class="content">
    <Breadcrumb :data="nav" />
    <Box v-loading="loading" class="pane">
      <div slot="header" class="pane-header">
        <div class="title">{{ detail.title | textFilter }}</div>
      </div>
      <div class="pane-body">
        <div v-html="detail.description"></div>
      </div>
      <!-- <div
        class="pane-cover"
        :style="{ backgroundImage: `url(${detail.picture})` }"
      ></div>
      <div class="pane-group">
        <h5 class="title">课程概述</h5>
        <div class="desc">{{ detail.description | textFilter }}</div>
      </div> -->
      <div class="pane-group">
        <h5 class="title">{{ $t('3baab4d') }}</h5>
        <div class="desc" style="line-height: 34px">
          <div>
            <span>{{ $t('ff45356') }}：</span>
            <em>{{ detail.date | textFilter }}</em>
          </div>
          <div>
            <span>{{ $t('9cf548f') }}：</span>
            <em>{{ detail.startTime }} - {{ detail.endTime }}</em>
          </div>
          <div>
            <span>{{ $t('6a7552b') }}：</span>
            <em>{{ detail.address | textFilter }}</em>
          </div>
          <div>
            <span>{{ $t('e192617') }}：</span>
            <em>{{ detail.organizer | textFilter }}</em>
          </div>
          <div>
            <span>{{ $t('1c703f7') }}：</span>
            <em>{{ detail.lector | textFilter }}</em>
          </div>
        </div>
      </div>
    </Box>

    <Box v-loading="loading" :title="$t('2c299e4')">
      <div class="edu-list">
        <div v-for="item in detail.others" :key="item.id" class="edu-item">
          <div
            class="cover"
            :style="{ backgroundImage: `url(${item.picture})` }"
          ></div>
          <div class="title">{{ item.title | textFilter }}</div>
          <ul class="desc">
            <li>
              <i class="el-icon-time"></i>
              <span>{{ $t('ff45356') }}：{{ item.date }} {{ item.startTime }}-{{
                item.endTime
              }}</span>
            </li>
            <li>
              <i class="el-icon-location-outline"></i>
              <span>{{ $t('6a7552b') }}：{{ item.address | textFilter }}</span>
            </li>
            <li>
              <i class="el-icon-user"></i>
              <span>{{ $t('e192617') }}：{{ item.organizer | textFilter }}</span>
            </li>
          </ul>
          <el-button class="btn" @click="jump(item.id)">{{ $t('ad6ab8a') }}</el-button>
        </div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Api from '@/api/information'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    Box,
    Breadcrumb,
  },
  data() {
    return {
      loading: false,
      detail: {},
    }
  },
  computed: {
    nav() {
      return [
        { name: this.$t('3a505c4'), path: '/information' },
        { name: this.$t('90e460a'), path: '/information/train' },
        { name: this.$t('8c7d6cd'), path: '' },
      ]
    }
  },
  watch: {
    '$route.query.id'() {
      this.getDetail()
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    jump(id) {
      this.$router.push({
        path: '/information/trainDetail',
        query: { id },
      })
    },
    getDetail() {
      const id = this.$route.query.id
      this.loading = true
      Api.getEduDetail(id)
        .then((res) => {
          this.detail = res
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 0 auto 20px;
}
.pane-body {
  img {
    width: 100% !important;
  }
}

.pane {
  &-header {
    // height: 80px;
    padding: 25px 30px;
    border-bottom: 1px solid #eee;
    font-size: 20px;
    color: #333333;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }

  &-body {
    // padding-left: 20px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 60px;
    img {
      width: 100% !important;
    }
  }

  // &-cover {
  //   height: 320px;
  //   background: #efefef no-repeat center;
  //   background-size: cover;
  //   margin-bottom: 40px;
  // }

  &-group {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 10px;
    }

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 20px;
    }

    .desc {
      padding-left: 20px;
      font-size: 14px;
      color: #666666;
      line-height: 24px;

      em {
        font-size: 14px;
        color: #333333;
        margin: 0 3px;
      }
    }
  }
}

.edu-list {
  // overflow: hidden;
  display: flex;

  .edu-item {
    box-sizing: border-box;
    margin-left: 20px;
    width: calc(33.3333% - (40px / 3));
    min-width: 0;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
    border: 1px solid #e5e8ed;
    background: #ffffff;

    &:first-child {
      margin-left: 0;
    }

    .cover {
      height: 200px;
      border-radius: 4px;
      background: #efefef no-repeat center;
      background-size: cover;
      margin-bottom: 40px;
    }

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 20px;
      margin-bottom: 24px;
    }

    .desc {
      li {
        padding-bottom: 16px;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }

      i {
        font-size: 16px;
        margin-right: 10px;
        color: #c3c3c3;
      }
    }

    .btn {
      display: block;
      margin: 20px auto;
      min-width: 120px;
      height: 40px;
      border-color: #00a4ff;
      color: #00a4ff;
    }
  }
}
.article {
  img {
    width: 100% !important;
  }
}
</style>
